import { gql } from '@apollo/client'

export const clientFragments = gql`
	fragment ClientData on Client {
		id
		name
		contact_name
		address
		address_2
		city
		state
		zip
		active
		formatted_address
		phone
		email
		domain
		billing_contact_name
		billing_contact_phone
		overage_limit
		user_id
		venue_user_id
		in_florida
		tax_exempt
		lead_jig_user_ids
		has_valid_payment_method
		has_compliance
		possible_duplicates_count
		ignored_possible_duplicate_ids
		rsvp_rate
		rsvp_fee_over_10k
		rsvp_fee_under_10k
		automation_rate
		household_discount
		billing_notes
		created_at
		updated_at
	}
`

export const returnAddressFragments = gql`
	fragment ReturnAddressData on ReturnAddress {
		id
		client_id
		address
		address_2
		city
		state
		zip
		formatted_address
	}
`

export const postOfficeFragments = gql`
	fragment PostOfficeData on PostOffice {
		id
		name
		scf
		state
		transit_from_tampa
		transit_from_tucson
		transit_from_tucson_priority
		transit_from_denver
		transit_from_houston
		primary_vendor
		created_at
		updated_at
	}
`

export const vendorFragments = gql`
	fragment VendorData on Vendor {
		id
		name
		services
		formatted_address
		address
		address_2
		city
		state
		zip
		contact_name
		phone
		phone_2
		email
		created_at
		updated_at
	}
`

export const accountFragments = gql`
	fragment AccountData on Account {
		id
		name
		contact_name
		email
		lead_jig_user_ids
		has_valid_payment_method
		rsvp_rate
		rsvp_fee_over_10k
		rsvp_fee_under_10k
		automation_rate
		is_fmo
		is_ria
		is_bd
		created_at
		updated_at
	}
`

export const topicFragments = gql`
	fragment TopicData on Topic {
		id
		name
		lead_jig_topic
		household_price
		created_at
		updated_at
	}
`

export const leadJigTopicFragments = gql`
	fragment LeadJigTopicData on LeadJigTopic {
		id
		value
		text
		created_at
		updated_at
	}
`

export const objectiveFragments = gql`
	fragment ObjectiveData on Objective {
		id
		name
		lead_jig_objective
		created_at
		updated_at
	}
`

export const digitalTemplateFragments = gql`
	fragment DigitalTemplateData on DigitalTemplate {
		id
		name
		primary_text
		headline
		description
		button_text
		created_at
		updated_at
	}
`

export const digitalTemplateImageFragments = gql`
	fragment DigitalTemplateImageData on DigitalTemplateImage {
		id
		name
		url
		presigned_url
		presigned_thumbnail_url
		tags
		created_at
		updated_at
	}
`

export const venueFragments = gql`
	fragment VenueData on Venue {
		id
		name
		venue_brand_id
		venue_type_id
		address
		address_2
		city
		state
		zip
		room_reservation_link
		formatted_address
		tier
		non_profit_only
		contact_name
		phone
		email
		website
		notes
		disclosure_text
		image_file_id
		primary_room_id
		banned_seminars
		banned_meals
		banned_topics
		banned_reason
		ban_notes
		disclosure_notes
		image_allowed
		disclosure_required
	}
`

export const venueRoomFragments = gql`
	fragment VenueRoomData on VenueRoom {
		id
		venue_id
		name
		address
		address_2
		city
		state
		zip
		coordinates
		formatted_address
		rental_rate
		resident_rate
		rental_type
		seating_capacity_classroom
		seating_capacity_theater
		onsite_parking
		parking_map_url
		parking_notes
		room_entry_details
		ada_accessibility
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const venueAmenityClassFragments = gql`
	fragment VenueAmenityClassData on VenueAmenityClass {
		id
		name
		created_at
		updated_at
	}
`

export const venueAmenityFragments = gql`
	fragment VenueAmenityData on VenueAmenity {
		id
		venue_room_id
		venue_amenity_class_id
		available
		description
		price
	}
`

export const venueBrandFragments = gql`
	fragment VenueBrandData on VenueBrand {
		id
		name
		created_at
		updated_at
	}
`

export const venueTypeFragments = gql`
	fragment VenueTypeData on VenueType {
		id
		name
		tier
		created_at
		updated_at
	}
`


export const mailerFormatFragments = gql`
	fragment MailerFormatData on MailerFormat {
		id
		name
		components
		papers
		fonts
		dimensions
		envelopes
		envelope_add_ons
		postage
		created_at
		updated_at
	}
`

export const mailerTemplateFragments = gql`
	fragment MailerTemplateData on MailerTemplate {
		id
		config
		tags
		name
		presigned_url(sample: true)
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const userFragments = gql`
	fragment UserData on User {
		id
		lead_jig_user_id
		active
		department
		role

		full_name
		first_name
		last_name
		email
		start_date

		department

		created_at
		updated_at
	}
`

export const userTimeOffFragments = gql`
	fragment UserTimeOffData on User {
		id
		is_employee
		start_date
		allocated_pto
		used_pto_hours
		pending_pto_hours
		remaining_pto
	}
`

export const timeOffFragments = gql`
	fragment TimeOffData on TimeOff {
		id
		start_time
		end_time
		used_hours
		used_days
		user_id
		paid
		available_by_phone
		available_by_web
		approved
		request_reason
		approval_reason
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const commissionGoalFragments = gql`
	fragment CommissionGoalData on CommissionGoal {
		id
		user_id
		year
		rate
		commissionable_amount_total
	}
`

export const blackoutDateFragments = gql`
	fragment BlackoutDateData on BlackoutDate {
		id
		date
		name
		description
		block_in_home_date_only
		updated_at
	}
`

export const orderFragments = gql`
	fragment OrderData on Order {
		id
		is_add_on
		client_id
		fmo_id
		ria_id
		bd_id
		lead_jig_user_id
		lead_jig_user_profile_id
		user_id
		approval_user_id
		venue_user_id
		webinar_user_id
		art_user_id
		data_user_id
		digital_user_id
		print_vendor_id
		mail_vendor_id
		published_at
		status
		approval_status
		venue_status
		webinar_status
		art_status
		data_status
		digital_status
		status_recently_updated
		data_status_recently_updated
		art_status_recently_updated
		digital_status_recently_updated
		has_digital
		email_user_id
		email_status
		email_status_recently_updated
		has_email
		email_approval_date_late
		email_approval_date_almost_late
		email_approval_date_almost_almost_late
		email_approval_date
		automation_user_id
		automation_status
		automation_status_recently_updated
		has_automation
		automation_approval_date_late
		automation_approval_date_almost_late
		automation_approval_date_almost_almost_late
		automation_approval_date
		needs_confirm_calls
		confirm_calls_amount
		art_approval_date_late
		data_approval_date_late
		digital_approval_date_late
		digital_deployment_date_late
		art_approval_date_almost_late
		art_approval_date_almost_almost_late
		data_approval_date_almost_late
		data_approval_date_almost_almost_late
		digital_approval_date_almost_late
		digital_approval_date_almost_almost_late
		digital_deployment_date_almost_late
		digital_deployment_date_almost_almost_late
		can_be_sourced
		job_number
		lead_jig_campaign_id
		reference_id
		should_be_invoiced
		has_invoice
		needs_to_be_invoiced
		invoice_number
		invoice_date
		topic_id
		topic {
			id
			name
		}
		topic_type
		is_webinar
		is_ready_made
		is_tailor_made
		objective_id
		headline
		art_notes
		data_notes
		client_notes
		production_notes
		digital_notes
		vendor_work_order_notes
		billing_notes
		mailer_format_id
		components
		paper
		font
		dimensions
		envelope
		envelope_add_on
		postage
		has_variable_data
		rsvp_amount
		automation_amount
		needs_leadjig
		needs_automation
		needs_phone_number
		redirect_phone
		digital_amount
		template
		deployment_page
		deployment_page_url
		digital_compliance
		digital_template
		digital_template_image_ids
		email_amount
		email_compliance
		radio_amount
		requested_in_home_date
		in_home_date
		scf_date
		mail_date
		print_delivery_date
		art_approval_date
		data_approval_date
		digital_approval_date
		digital_deployment_date
		first_event_date
		mail_quantity
		sample_quantity
		per_piece_price
		postage_price
		subtotal
		adjusted_subtotal: subtotal(only_approved: false)
		total
		adjusted_total: total(only_approved: false)
		paid_amount
		paid_pending_amount
		paid_declined_amount
		remaining_balance
		return_address
		return_address_id
		entered_in_accounting
		paused_date
		cities
		states
		city_state
		mailer_data
		order_type
		digital_lure_codes
		direct_mail_lure_codes
		direct_mail_lure_numbers
		available_unlock_funds
		lures {
			id
			channel
			name
			code
			number
			mailjig_campaign_id
			fb_campaign_id
			builder_page_id
		}
		created_at
		updated_at
	}
`

export const orderPerformanceRequestFragments = gql`
	fragment OrderPerformanceRequestData on OrderPerformanceRequest {
		id
		order_id
		maximum_households
		expected_households
		number_of_events
		booking_type
		date_type
		date_range_start
		date_range_end
		preferred_dates_and_times
		blackout_dates
		venue_notes
		venue
		city
		state
		requested_zip_codes
		excluded_zip_codes
		additional_zip_codes
		seating_style
		duration
		refreshments
		needs_screen
		needs_projector
		venue_option_ids
		created_at
		updated_at
	}
`

export const orderTableFragments = gql`
	fragment OrderTableData on Order {
		id
		approval_status
		approval_status_recently_updated
		art_approval_date
		art_approval_date_almost_almost_late
		art_approval_date_almost_late
		art_approval_date_late
		art_status
		art_status_recently_updated
		automation_amount
		automation_status
		automation_status_recently_updated
		cities
		data_approval_date
		data_approval_date_almost_almost_late
		data_approval_date_almost_late
		data_approval_date_late
		data_status
		data_status_recently_updated
		digital_amount
		digital_approval_date
		digital_approval_date_almost_almost_late
		digital_approval_date_almost_late
		digital_approval_date_late
		digital_compliance
		digital_deployment_date
		digital_status
		digital_status_recently_updated
		email_amount
		email_compliance
		email_status
		email_status_recently_updated
		first_event_date
		has_automation
		has_digital
		has_email
		in_home_date
		invoice_date
		invoice_number
		is_add_on
		job_number
		mail_date
		mail_quantity
		mailer_data
		needs_confirm_calls
		order_type
		topic_type
		paid_amount
		paid_pending_amount
		print_delivery_date
		states
		status
		status_recently_updated
		total
		venue_status
		venue_status_recently_updated
		zips
	}
`

export const subscriptionFragments = gql`
	fragment SubscriptionData on Subscription {
		id
		client_id
		payment_method_id
		subscription_service_tier_id
		status
		name
		description
		interval_length
		interval_unit
		amount
		creator_id
		updater_id
		created_at
		updated_at
		canceled_at
	}
`

export const subscriptionServiceFragments = gql`
	fragment SubscriptionServiceData on SubscriptionService {
		id
		name
		description
		revenue_account
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const subscriptionServiceTierFragments = gql`
	fragment SubscriptionServiceTierData on SubscriptionServiceTier {
		id
		subscription_service_id
		interval_length
		interval_unit
		amount
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const eventFragments = gql`
	fragment EventData on Event {
		id
		title
		capacity
		meals
		meal_options
		start_time
		end_time
		order_id
		venue_id
		venue_room_id
		created_at
		updated_at
	}
`

export const adjustmentFragments = gql`
	fragment AdjustmentData on Adjustment {
		id
		order_id
		amount
		reason
		approved
		approval_reason
		is_taxable
		created_at
		updated_at
	}
`

export const paymentFragments = gql`
	fragment PaymentData on Payment {
		id
		transaction_id
		order_id
		amount
		pending
		external_payment
		approval_code
		card_type
		card_number
		message
		notes
		response_reason_description
		error_message
		created_at
		updated_at
		is_valid
		is_stripe
		status
		transaction_status
		transaction_settled
		can_be_voided
		can_be_refunded
		can_be_captured
		refundable_amount
		voided
		is_refund
		refunded
		declined
		is_pending
		is_authorized
	}
`

export const paymentMethodFragments = gql`
	fragment PaymentMethodData on PaymentMethod {
		id
		client_id
		account_id
		name_on_card
		first_name
		last_name
		company
		exp_month
		exp_year
		address
		city
		state
		zip
		phone_number
		signer_full_name
		signer_email
		creator_id
		updater_id
		created_at
		updated_at
		last4
		long_masked_number
		card_type
		provider_name
		authorized
		default
		is_stripe
		is_bank_account
		is_credit_card
		tailor_made_only
		status
		has_contract
		owner
		errors
	}
`

export const paperlessFileFragments = gql`
	fragment PaperlessFileData on PaperlessFile {
		id
		order_id
		name
		category
		file_name
		url
		size
		creator_id
		updater_id
		created_at
		updated_at
		deleted_at
	}
`

export const imageFileFragments = gql`
	fragment ImageFileData on ImageFile {
		id
		name
		file_name
		presigned_url
		presigned_thumbnail_url
		size
		creator_id
		updater_id
		created_at
		updated_at
		deleted_at
	}
`

export const accountingProductFragments = gql`
	fragment AccountingProductData on AccountingProduct {
		id
		accounting_code
		description
		commissionable
		active
	}
`

export const invoiceLineItemFragments = gql`
	fragment InvoiceLineItemData on InvoiceLineItem {
		id
		invoice_id
		quantity
		accounting_product_id
		accounting_product {
			...AccountingProductData
		}
		description
		unit_price
		amount
		tax_code
		created_at
		updated_at
	}
	${accountingProductFragments}
`

export const invoiceFragments = gql`
	fragment InvoiceData on Invoice {
		id
		invoice_date
		order_id
		subscription_id
		client_id
		invoice_number
		subtotal
		total
		notes
		is_house_order
		in_florida
		tax_exempt
		invoice_line_items {
			...InvoiceLineItemData
		}
		created_at
		updated_at
	}
	${invoiceLineItemFragments}
`

export const batchFragments = gql`
	fragment BatchData on Batch {
		id
		settlement_time_utc
		settlement_time_local
		settlement_state
		payment_method
		market_type
		product
	}
`

export const transactionFragments = gql`
	fragment TransactionData on Transaction {
		id
		batch_id
		submit_time_utc
		submit_time_local
		transaction_status
		first_name
		last_name
		account_type
		account_number
		settle_amount
		market_type
		product
		profile
		subscription
	}
`

export const commissionReportFragments = gql`
	fragment CommissionReportData on CommissionReport {
		id
		user_id
		start_date
		end_date
		amount_total
		commissionable_amount_total
		uncommissionable_amount_total
		commission_amount_total
		signature_text
		signature
		report
		created_at
		updated_at
	}
`

export const settingFragments = gql`
	fragment SettingData on Setting {
		id
		name
		value
		encoded_value
		value_type
		creator_id
		updater_id
		created_at
		updated_at
	}
`

export const logFragments = gql`
	fragment LogData on Log {
		last_evaluated_key
		items
	}
`
